import React, { Component } from "react";
import {
  PredefinedChallengeApi,
  PredefinedChallenge,
} from "../../../api/generated";

interface SelectorState {
  predefinedChallenges: PredefinedChallenge[];
  selectedChallenge: any;
}
export class Selector extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      predefinedChallenges: [],
      selectedChallenge: {},
    } as SelectorState;

    this.selectedChanged = this.selectedChanged.bind(this);
  }
  componentDidMount() {
    let predefinedChallengeApi = new PredefinedChallengeApi();
    predefinedChallengeApi.predefinedChallengeListList().then((challenges) => {
      this.setState({ predefinedChallenges: challenges.data });
      this.setState({
        selectedChallenge: challenges.data[challenges.data.length - 1],
      });
    });
  }
  radioButtonStyle = {
    minWidth: "1.5rem",
  };

  createCustomChallenge(event: any) {
    this.props.onCustomChallengeClick(event);
  }

  selectedChanged(event: any) {
    this.setState({
      selectedChallenge: this.state.predefinedChallenges[event.target.value],
    });
  }

  render() {
    return (
      <div className="flex flex-col space-y-8">
        <div className="form-control">
          {/* select challenge */}
          {this.state.predefinedChallenges.map(
            (challenge: PredefinedChallenge, i: number) => {
              return (
                <label className="cursor-pointer label">
                  <div className="flex flex-col	">
                    <span className="font-bold">{challenge.name}</span>{" "}
                    <span className="label-text whitespace-pre-line		overflow-ellipsis">
                      {challenge.description}
                    </span>
                  </div>
                  <input
                    type="radio"
                    name="opt"
                    defaultChecked={true}
                    // checked={true}
                    className="radio radio-alps radio-primary w-6 radio-alps"
                    value={i}
                    style={this.radioButtonStyle}
                    onClick={this.selectedChanged}
                  />
                </label>
              );
            }
          )}
        </div>

        {/* divider, button create an individualChallenge */}
        {/* divider */}
        <div className="flex items-center">
          <div className="flex-grow bg bg-gray-300 h-0.5"></div>
          <div className="flex-grow-0 mx-5">oder</div>
          <div className="flex-grow bg bg-gray-300 h-0.5"></div>
        </div>

        {/* button for create individual challenge */}
        <button
          onClick={(e) => this.props.onCustomChallengeClick(e)}
          // onClick={this.createCustomChallenge}
          className="btn btn-alps md:w-4/6 self-center"
          value="custom-challenge"
        >
          Erstelle deine eigene Challenge
        </button>
      </div>
    );
  }
}

export default Selector;
