export function nameForDifficulty(
  difficulty: number,
  cheatday: boolean = false
): string {
  if (cheatday) {
    return "cheatday";
  }
  if (difficulty < 25) {
    return "Leicht geschafft";
  } else if (difficulty < 50) {
    return "Geschafft";
  } else if (difficulty < 75) {
    return "Gerade so geschafft";
  }

  return "Nicht geschafft";
}

export function colorClassNameForDifficulty(
  difficulty: number,
  cheatday: boolean = false
): string {
  if (cheatday) {
    return "bg-gray-400 border-gray-400";
  }
  if (difficulty < 25) {
    return "bg-green-400 border-green-400";
  } else if (difficulty < 50) {
    return "bg-blue-400	border-blue-400	";
  } else if (difficulty < 75) {
    return "bg-purple-400 border-purple-400	";
  }

  return "bg-pink-400 border-pink-400";
}

export function textColorForDifficulty(difficulty: number): string {
  if (difficulty < 25) {
    return "text-green-400";
  } else if (difficulty < 50) {
    return "text-blue-400";
  } else if (difficulty < 75) {
    return "text-purple-400";
  }

  return "text-pink-400";
}
