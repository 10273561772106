import React, { Component } from "react";
import { Link } from "react-router-dom";

export class NotFound extends Component {
  render() {
    return (
      <div className="flex flex-col justify-start align-middle h-full">
        <h1>Die von die angeforderte Challenge exisitert leider nicht.</h1>
        <Link to="/">
          <button className="btn btn-large bg-blue-500 border-blue-500 mt-8 hover:border-green-400 hover:bg-green-400">
            Zurück zur Startseite
          </button>
        </Link>
      </div>
    );
  }
}

export default NotFound;
